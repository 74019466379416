import React from 'react';
import { Carousel } from 'react-bootstrap';
import "../../styles/homepublic.scss"

const PrezziResponsiveNegozioSingoli = () => {
  const pricingPlans = [
    {
      name: 'Visura Camerale',
      subtitle: '',
      features: [
        { label: 'Visura Camerale', value: '1' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'Ricerca Azienda',
      subtitle: '',
      features: [
        { label: 'Ricerca Azienda', value: '30' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'Bilancio Azienda',
      subtitle: '',
      features: [
        { label: 'Bilancio Azienda', value: '1' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'Report Smart',
      subtitle: '',
      features: [
        { label: 'Report Smart', value: '1' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'Report Azienda',
      subtitle: '',
      features: [
        { label: 'Report Azienda', value: '1' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'Report Azienda Approfondito',
      subtitle: '',
      features: [
        { label: 'Report Azienda Approfondito', value: '1' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    }
  ];

  return (
    <div className="col-sm-12 control-r">
      <Carousel>
        {pricingPlans.map((plan, index) => (
          <Carousel.Item key={index}>
            <div className="col-sm-12 box-piani-box">
              <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                <div className="col-sm-12 box-piani-boxes-header">
                  <p className="box-testimonial-title p-lr-30">
                    {plan.name} <br />
                    <span className="box-piani-span">{plan.subtitle}</span>
                  </p>
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    {plan.features.map((feature, featureIndex) => (
                      <React.Fragment key={featureIndex}>
                        <div className="col-sm-9">
                          <span className="box-piani-row-text">{feature.label}</span>
                        </div>
                        <div className="col-sm-3">
                          <span className="box-piani-num">{feature.value}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default PrezziResponsiveNegozioSingoli;