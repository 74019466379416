import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setAssistenzaMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { addAssistenza, inserisciAssistenza, updateAssistenza } from "services/assistenzaService";
import { parseJwtToken } from "services/tokenService";
import { getOneWs_Login } from "services/ws_loginService";
import moment from "moment";
import { sendMailAssistenza } from "services/mailService";

import img_logomyimpresawhite from "../asset/logo_mi_white.png"
import logo from "../asset/logo_myimprese.png"
import { useNavigate } from "react-router";
import NavigationMenu from "template/NavigationMenu";
import FooterPage from "template/FooterPage";

import img_anagrafica from "../asset/dettaglio.png"
import img_bilancio from "../asset/bilanci.png"
import img_esponenti from "../asset/esponenti.png"
import img_sedi from "../asset/sedi.png"
import img_partecipazioni from "../asset/partecipazioni.png"
import img_report from "../asset/report.png"
import img_screen_interno from "../asset/immagine_ricerca_.png"
import img_scree_interno_post from "../asset/lista_salvate_myi_s.png"



type Props = {
    row?: any,
    // hideShowForm: (actionName) => void;
    // getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const ComeFunziona: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const iValue={id:'',id_utente:'',nome:'',cognome:'',azienda:'',email:'',telefono:'',descrizione:'', acceptTerms: false};
    //let initialValues = iValue
   
    

    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    var idUtente = 0
    let iValueUser = {
        id: '',
        id_utente: '',
        nome: '',
        cognome: '',
        azienda: '',
        email: '',
        telefono: '',
        descrizione: '',
        acceptTerms: false
    }
    const iValueEmpty = {
        id: '',
        id_utente: '',
            nome: '',
            cognome: '',
            azienda: '',
            email: '',
            telefono: '',
            descrizione: '',
            acceptTerms:false
    }

    const[datiLoad, setDatiLoad] = useState(null);

    const[id_utente, setIdUtente] = useState('');
    const[nome, setNome] = useState('');
    const[cognome, setCognome] = useState('');
    const[azienda, setAzienda] = useState('');
    const[email, setEmail] = useState('');
    const[telefono, setTelefono] = useState('');
    const[descrizione, setDescrizione] = useState('');

    const[messaggio, setMessaggio] = useState("")
    const[esito, setEsito] = useState(false)

    
    const videoPath_comefunziona = require("../asset/come_funziona_myimprese_c.mp4");
    const videoPath_presentazione = require("../asset/myimprese_presentazione_video.mp4");

    useEffect(() => {
        
        if(jwtToken && jwtToken.data != null ){
            idUtente = jwtToken.data.id;
    
            if(datiLoad == null){
                getOneWs_Login(idUtente).then((response) => {
                    if (response && response.data && response.data.document) {
                      //console.log(response);
                      const iValueUser_set = {
                        id: '',
                        id_utente: response.data.document.id,
                        nome: response.data.document.nome_referente,
                        cognome: response.data.document.cognome_referente,
                        azienda: response.data.document.nome_azienda,
                        email: response.data.document.email,
                        telefono: response.data.document.telefono,
                        descrizione: '',
                        acceptTerms:false
                    }
        
                    setAzienda(response.data.document.nome_azienda)
                    setEmail(response.data.document.email)
                    setTelefono(response.data.document.telefono)
                    iValueUser = iValueUser_set;
                    setDatiLoad(iValueUser);
    
                    }else{
                        iValueUser = iValueEmpty
                        setDatiLoad(iValueUser);
                    }
                  })
            }
            
        }else{
            iValueUser = iValueEmpty
        }
      }, [datiLoad]);
    
    const formik = useFormik({
        initialValues: {
            id_utente: 0,
            nome: "",
            cognome: "",
            email: "",
            azienda:"",
            telefono:"",
            stato: 0,
            data_richiesta: moment(),
            descrizione:"",
            acceptTerms: false
        },
        onSubmit: async (values) => {
            setEsito(false)

            if(jwtToken && jwtToken.data != null){

                let azienda = datiLoad.azienda
                if(datiLoad.azienda == null || datiLoad.azienda == "") azienda = datiLoad.cognome + " " + datiLoad.nome
                values.id_utente =jwtToken.data.id
                values.nome = datiLoad.nome
                values.cognome = datiLoad.cognome
                values.email = datiLoad.email
                values.telefono = datiLoad.telefono
                values.azienda = azienda
                values.acceptTerms = datiLoad.acceptTerms
            }
                const response = await inserisciAssistenza(values);
                if (response) {
                    setMessaggio("Richiesta inserita con successo")
                    setEsito(true)

                    
                    values.nome = ""
                    values.cognome = ""
                    values.email = ""
                    values.telefono = ""
                    values.azienda = ""
                    values.descrizione = ""
                    values.acceptTerms = false

                } else {
                    //dispatch(setAssistenzaMessage("Some error occured!"));
                    setMessaggio("Richiesta non inserita con successo")
                    setEsito(true)
                }
        },
        
        validationSchema: yup.object({
            //id_utente: yup.number().nullable(),
            nome: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            cognome: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            // azienda: yup.lazy((value) => {
            //     return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            // }),
            email:yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            telefono:yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            descrizione: yup.string().required('Campo Obbligatorio'),
            acceptTerms: yup.boolean().oneOf([true], 'Devi accettare le condizioni del servizio'),
            //stato: yup.number().required('stato is required'),
        }),
    });

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])



    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLoging = () => {
    navigate('/', { replace: true });
  }

  const MenuItems = () => (

    <>
      <p onClick={()=>handleLoging()} className="font-menu cursor-pointer hover:text-blue-500 transition-colors">Home</p>
    </>
  );

  const handleNewRequest = () =>{
    setEsito(false)
    setNome("")
    setCognome("")
    setAzienda("")
    setEmail("")
    setTelefono("")
    setDescrizione("")
  }

  const handleRegister = () => {
    navigate('/registrazione', { replace: true });
}
const handleConatti = () => {
    navigate('/assistenza', { replace: true });
}

useEffect(() => {
    // Disabilita il tasto destro su tutta la pagina
    const disableRightClick = (e) => e.preventDefault();
    document.addEventListener("contextmenu", disableRightClick);
    
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

    return (
        <>
        <div className="">
            <NavigationMenu isLoading={false}/>
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            
                                            <Row>
                                              
                                              <div className="col-sm-6 col-xs-12">
                                              <h1 className="h4 text-gray-900 mb-4">Presentazione</h1>
                                              <video width="100%" controls>
                                                <source src={videoPath_presentazione} type="video/mp4" />
                                                
                                              </video>
                                              </div>
                                              <div className="col-sm-6 col-xs-12">
                                              <h1 className="h4 text-gray-900 mb-4">Come Funziona</h1>
                                              <video width="100%" controls>
                                                <source src={videoPath_comefunziona} type="video/mp4" />
                                                
                                              </video>
                                              </div>
                                            </Row>
                                            <Row>
                                              {jwtToken.data == null &&
                                              <>
                                               <Row className="p-4 box-negozio-info m-top-50 m-bottom-30">
  {jwtToken.data == null &&
  <>
   <div className='col-sm-9 d-flex align-items-center text-left p-bottom-30'>
                                                      <p className="mb-0">Vuoi provare il servizio? Per te subito 5 Ricerche Gratuite e 2 visualizzazioni dettagliate di imprese e per il primo acquistato
                                                        il 20% di sconto
                                                      </p>
                                                    </div>
                                                    <div className='col-sm-3 d-flex align-items-center justify-content-end p-bottom-30'>
                                                      <Button variant="info" className="" 
                                                              disabled={false} 
                                                              onClick={() => handleRegister()}>
                                                        <i className="fa-solid fa-user-plus"></i> Registrati Ora
                                                      </Button>
                                                    </div>
  </>
                                                   
}
    <div className='col-sm-9 d-flex align-items-center text-left'>
      <p className="mb-0">Vuoi maggiori informazioni o un piano personalizzato?</p>
    </div>
    <div className='col-sm-3 d-flex align-items-center justify-content-end'>
      <Button variant="info" className="" 
              disabled={false} 
              onClick={() => handleConatti()}>
        <i className="fa-regular fa-envelope-open"></i> Contattaci Ora
      </Button>
    </div>
  </Row>
                                              </>
                                              

                                              }
                                            </Row>
                                            <Row className=" text-center">
                                            <div className="col-sm-12 col-xs-12"> <h1 className="h4 color-blue mb-4">Come effettuare una ricerca e scaricare la lista delle imprese</h1></div>
                                           
                                            </Row>
                                            <Row className="p-top-50">
                                              
                                              <div className="col-sm-6 col-xs-12">
                                              <h1 className="h4 text-gray-900 mb-4">Ricerca Impresa</h1>
                                              <p className="text-align-j">
                                                In questa sezione potrai effetturare le tue ricerche utilizzando fino a 14 filtri e costruire le tue liste tra cui:
                                                <ul>
                                                    <li>filtri territoriali, tra cui provincia, comune e cap</li>
                                                    <li>filtri per forma giuridica, ateco e stati attività tra cui: forma giuridica, natura giuridica, tipo sede, ateco primario e secondario</li>
                                                    <li>filtri su ricavi, addetti, presenza sul mercato tra cui: range ricavi, range numero addetti, range temporali</li>
                                                    <li>salvare la ricerca effettuata per essere riutilizzata in seguito o scaricare in EXCEL il risultato della ricerca con il suo dettaglio </li>
                                                    <li>richiedere visura camerale e ultimo bilancio depositato in PDF direttamente dal sistema camerale</li>

                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              <div className="col-sm-6 col-xs-12">
                                              <img src={img_screen_interno} className="come-fare-img" onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                            </Row>
                                            <Row className="p-top-50 d-flex flex-column flex-sm-row">
                                            <div className="col-sm-6 col-xs-12 order-2 order-sm-1">
                                              <img src={img_scree_interno_post} className="come-fare-img" 
                                                onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                              
                                              <div className="col-sm-6 col-xs-12 order-1 order-sm-2">
                                              <h1 className="h4 text-gray-900 mb-4">Lista Imprese</h1>
                                              <p className="text-align-j">
                                                In questa sezione troverai tutte le tue ricerche salvate per poter:
                                                <ul>
                                                    <li>controllare i dati di ricerca inseriti</li>
                                                    <li>effettuare nuovamente la ricerca, utile per ricerche ricorrenti e temporali</li>
                                                    <li>acquistare la lista delle imprese trovate con i relativi dati di dettaglio</li>
                                                    <li>costruire le tue liste marketing</li>
                                                    <li>scaricare le tue liste imprese in EXCEL</li>

                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              
                                            </Row>
                                            <Row className="p-top-50 text-center">
                                            <div className="col-sm-12 col-xs-12"> <h1 className="h4 color-blue mb-4">Cosa potrai visualizzare con il Dettaglio Impresa</h1></div>
                                           
                                            </Row>
                                            <Row className="p-top-50">
                                              
                                              <div className="col-sm-6 col-xs-12">
                                              <h1 className="h4 text-gray-900 mb-4">Anagrafica</h1>
                                              <p className="text-align-j">
                                                In questa sezione troverai tutte le informazioni essenziali sull' impresa tra cui:
                                                <ul>
                                                    <li>dati identificativi, che comprendono ragione sociale, sede e partita IVA, telefono, sito internet e mail</li>
                                                    <li>indice di affidabilità, un parametro utile per valutare la solidità aziendale </li>
                                                    <li>settore in cui opera l'azienda, con i relativi codici ATECO principale e secondario</li>
                                                    <li>numero di addetti aziendali</li>
                                                    <li>certificazioni e attestazioni che garantiscono la qualità e la conformità dei servizi</li>

                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              <div className="col-sm-6 col-xs-12">
                                              <img src={img_anagrafica} className="come-fare-img" onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                            </Row>
                                            <Row className="p-top-50 flex-column flex-sm-row">
                                            <div className="col-sm-6 col-xs-12 order-2 order-sm-1">
                                              <img src={img_bilancio} className="come-fare-img" onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                              
                                              <div className="col-sm-6 col-xs-12 order-1 order-sm-2">
                                              <h1 className="h4 text-gray-900 mb-4">Bilancio</h1>
                                              <p className="text-align-j">
                                                In questa sezione troverai tutte le informazioni fino agli ultimi 3 bilanci:
                                                <ul>
                                                    <li>Trend Ricavi e Debiti</li>
                                                    <li>dati riepilogo, che comprendono fatturato, utile, patrimonio netto e totale debiti</li>
                                                    <li>trend grafico Crediti Clienti </li>
                                                    <li>trend grafico Debiti verso Banche </li>
                                                    <li>trend grafico  Debiti verso Fornitori </li>
                                                    <li>trend grafico Fatturato </li>
                                                    <li>trend grafico Patrimonio Netto </li>
                                                    <li>trend grafico Totale Debiti</li>
                                                    <li>trend grafico Utile Perdita </li>

                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              
                                            </Row>
                                            <Row className="p-top-50">
                                              
                                              <div className="col-sm-6 col-xs-12">
                                              <h1 className="h4 text-gray-900 mb-4">Esponenti</h1>
                                              <p className="text-align-j">
                                                In questa sezione troverai le informazioni su soci, amministratori ed esponenti:
                                                <ul>
                                                    <li>numero soci</li>
                                                    <li>numero esponenti</li>
                                                    <li>dati identificativi socio, quota posseduta e tipo diritto</li>
                                                    <li>dati proncipali esponente e tipo carica </li>

                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              <div className="col-sm-6 col-xs-12">
                                              <img src={img_esponenti} className="come-fare-img" onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                            </Row>
                                            {/* <Row className="p-top-50 d-flex flex-wrap">
                                            <div className="col-sm-6 col-xs-12 order-1 order-sm-2">
                                              <img src={img_sedi} className="come-fare-img" onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                              
                                              <div className="col-sm-6 col-xs-12 order-2 order-sm-1">
                                              <h1 className="h4 text-gray-900 mb-4">Sedi e Unità Locali</h1>
                                              <p className="text-align-j">
                                                In questa sezione troverai tutte le informazioni relativo alle sedi aziendali:
                                                <ul>
                                                    <li>Posizionamento su mappa</li>
                                                    <li>Tipo di immobili posseduti</li>
                                                    <li>Tipo di Sede</li>
                                                    <li>Indirizzo </li>

                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              
                                            </Row> */}
                                            <Row className="p-top-50 d-flex flex-column flex-sm-row">
                                                {/* Sezione Testo - Sempre in cima su mobile */}
                                                <div className="col-12 col-sm-6 order-1 order-sm-2">
                                                    <h1 className="h4 text-gray-900 mb-4">Sedi e Unità Locali</h1>
                                                    <p className="text-align-j">
                                                    In questa sezione troverai tutte le informazioni relative alle sedi aziendali:
                                                    </p>
                                                    <ul>
                                                    <li>Posizionamento su mappa</li>
                                                    <li>Tipo di immobili posseduti</li>
                                                    <li>Tipo di Sede</li>
                                                    <li>Indirizzo</li>
                                                    </ul>
                                                </div>

                                                {/* Sezione Immagine - Posizione sotto il testo su mobile */}
                                                <div className="col-12 col-sm-6 order-2 order-sm-1">
                                                    <img 
                                                    src={img_sedi} 
                                                    className="come-fare-img" 
                                                    onContextMenu={(e) => e.preventDefault()} 
                                                    draggable="false" 
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onDragStart={(e) => e.preventDefault()}
                                                    />
                                                </div>
                                                </Row>

                                            <Row className="p-top-50">
                                              
                                              <div className="col-sm-6 col-xs-12">
                                              <h1 className="h4 text-gray-900 mb-4">Partecipazioni</h1>
                                              <p className="text-align-j">
                                                In questa sezione troverai le informazioni sulle partecipazioni aziendali:
                                                <ul>
                                                    <li>numero partecipazioni</li>
                                                    <li>dati identificativi, quota posseduta e tipo diritto</li>
                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              <div className="col-sm-6 col-xs-12">
                                              <img src={img_partecipazioni} className="come-fare-img" onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                            </Row>
                                            <Row className="p-top-50 flex-column flex-sm-row">
                                            <div className="col-sm-6 col-xs-12 order-2 order-sm-1">
                                              <img src={img_report} className="come-fare-img" onContextMenu={(e) => e.preventDefault()} 
                                                draggable="false" onMouseDown={(e) => e.preventDefault()}
                                                onDragStart={(e) => e.preventDefault()}/>
                                              
                                              </div>
                                              
                                              <div className="col-sm-6 col-xs-12 order-1 order-sm-2">
                                              <h1 className="h4 text-gray-900 mb-4">Report Impresa</h1>
                                              <p className="text-align-j">
                                                In questa sezione potrai richiedere i report aziendali in PDF:
                                                <ul>
                                                    <li>Report Smart: anagrafica azienda, dettaglio sedi, dati fino a ultimi 3 bilanci, soci ed esponenti</li>
                                                    <li>Deep Dossier: scheda aziendale, negatività, dati fino a ultimi 3 bilanci con commenti, scheda soci ed esponenti</li>
                                                    <li>Executive Report: scheda aziendale, negatività, dati fino a ultimi 3 bilanci con commenti, scheda soci ed esponenti, conclusioni e informazioni di sintesi</li>
                                                   

                                                </ul>

                                                 </p>
                                              
                                              </div>
                                              
                                            </Row>
                                            <Row>
                                              {jwtToken.data == null &&
                                              <>
                                               <Row className="p-4 box-negozio-info m-top-50 m-bottom-30">
  {jwtToken.data == null &&
  <>
   <div className='col-sm-9 d-flex align-items-center text-left p-bottom-30'>
                                                      <p className="mb-0">Vuoi provare il servizio? Per te subito 5 Ricerche Gratuite e 2 visualizzazioni dettagliate di imprese e per il primo acquistato
                                                        il 20% di sconto
                                                      </p>
                                                    </div>
                                                    <div className='col-sm-3 d-flex align-items-center justify-content-end p-bottom-30'>
                                                      <Button variant="info" className="" 
                                                              disabled={false} 
                                                              onClick={() => handleRegister()}>
                                                        <i className="fa-solid fa-user-plus"></i> Registrati Ora
                                                      </Button>
                                                    </div>
  </>
                                                   
}
    <div className='col-sm-9 d-flex align-items-center text-left'>
      <p className="mb-0">Vuoi maggiori informazioni o un piano personalizzato?</p>
    </div>
    <div className='col-sm-3 d-flex align-items-center justify-content-end'>
      <Button variant="info" className="" 
              disabled={false} 
              onClick={() => handleConatti()}>
        <i className="fa-regular fa-envelope-open"></i> Contattaci Ora
      </Button>
    </div>
  </Row>
                                              </>
                                              

                                              }
                                            </Row>
                                            
                                            
                                        </div>
 
                                           
                                        
                                        

                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <FooterPage />
    </>
    );
}

