import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setAssistenzaMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addAssistenza, updateAssistenza } from "services/assistenzaService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { parseJwtToken } from "services/tokenService";
import { getOneWs_Login } from "services/ws_loginService";
import moment from "moment";
import LoadingSpinner from "components/LoadingSpinner";
import { sendMailAssistenza } from "services/mailService";
import ConfirmationAssistenza from "template/ConfirmationAssistenza";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const AssistenzaForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',id_utente:'',data_richiesta:'',stato:'',nome:'',cognome:'',azienda:'',email:'',telefono:'',descrizione:'',risposta:''};
    const initialValue = action === 'edit' ? row : iValue;
    const [datiProfilo, setDatiProfilo] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);

    //console.log(jwtToken.data)

    useEffect(() => {
        if(datiProfilo == null) getDatiProfilo()
    })
    
    const getDatiProfilo = () => {
      
      if(jwtToken){
          let idUtente = jwtToken.data.id;
  
          if(datiProfilo == null){
              getOneWs_Login(idUtente).then((response) => {
                  if (response && response.data && response.data.document) {
                    //console.log(response);
                    const iValueUser_set = {
                      id: '',
                      id_utente: response.data.document.id,
                      nome: response.data.document.nome_referente,
                      cognome: response.data.document.cognome_referente,
                      azienda: response.data.document.nome_azienda,
                      email: response.data.document.email,
                      telefono: response.data.document.telefono,
                      partitaiva: response.data.document.partita_iva,
                      descrizione: ''
                  }
      
                  setDatiProfilo(iValueUser_set);
  
                  }
                })
          }
          
      }
    }
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setIsLoading(true)
            if (action === 'edit') {
                const response = await updateAssistenza(values);
                if (response && response.status == 200) {

                    dispatch(setAssistenzaMessage("Richiesta Assistenza aggiornata con successo"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                    let email = jwtToken.data.email

                    let dati = {
                        email: values.email,
                        messaggio : values.risposta,
                        idrich: values.id


                    }
                    sendMailAssistenza(dati).then((result)=>{
                        console.log(result)
                        setShowConfirm(true)
                        setIsLoading(false)
                    })

                    setIsLoading(false)
                } else {
                    dispatch(setAssistenzaMessage("Si è verificato un errore, riprovare!"));
                    setIsLoading(false)
                }
            } else if (action === 'add') {
                if(datiProfilo.id_utente != 1){
                    let azienda = datiProfilo.azienda
                    if(datiProfilo.azienda == null || datiProfilo.azienda == "") azienda = datiProfilo.cognome + " " + datiProfilo.nome
                    values.id_utente = datiProfilo.id_utente
                    values.nome = datiProfilo.nome;
                    values.cognome = datiProfilo.cognome;
                    values.email = datiProfilo.email;
                    values.azienda = azienda;
                    values.telefono = datiProfilo.telefono;
                    values.stato = 0;
                }
                
                values.data_richiesta = moment();

                const response = await addAssistenza(values);
                if (response && response.status == 201) {
                    //console.log(response)
                    dispatch(setAssistenzaMessage("Richiesta Assistenza inserita con successo"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                    

                    let idrich = response.data.document
                    
                    let dati = {
                        email: "tecnoinnovis@gmail.com",
                        messaggio : values.descrizione,
                        idrich: idrich


                    }
                    sendMailAssistenza(dati).then((result)=>{
                        //console.log(result)
                        setShowConfirm(true)
                        setIsLoading(false)
                    })
                } else {
                    dispatch(setAssistenzaMessage("Si è verificato un errore, riprovare!"));
                    setIsLoading(false)
                }
            }
        },
        validationSchema: yup.object({
            id_utente: yup.number().nullable(),
            //data_richiesta: yup.date().required('data_richiesta is required'),
            //stato: yup.number().required('stato is required'),
            //nome: yup.string().required('nome is required'),
            //cognome: yup.string().required('cognome is required'),
            //azienda: yup.string().required('azienda is required'),
            //email: yup.string().required('email is required'),
            //telefono: yup.string().required('telefono is required'),
            descrizione: yup.string().required('descrizione is required'),
            //risposta: yup.string().required('risposta is required'),

        }),
    });
    return (
        <>
        {isLoading ? <LoadingSpinner /> : isLoading}
        
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h4 className="">Richiesta Assistenza
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Assistenza</span> */}
                        <Button variant="info" className="float-right"  onClick={() => hideShowForm(false)}><i className="fa-solid fa-long-arrow-left"></i> Lista</Button>
                    {/* </Button> */}
                </h4>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                {jwtToken != null && jwtToken.data.id == 1 && 
                <>
                <Form.Group>
                <label className="form -control-label">id_utente</label>
                <Form.Control type="text" name="id_utente" className="form-control" value={formik.values.id_utente}
                onChange={formik.handleChange}
                onBlur ={formik.handleBlur}
                isInvalid ={!!formik.touched.id_utente && !!formik.errors.id_utente}
                isValid ={!!formik.touched.id_utente && !formik.errors.id_utente}
                ></Form.Control>
                {
                    formik.errors.id_utente && (
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.id_utente}
                    </Form.Control.Feedback>
                )}
                </Form.Group>
                <Form.Group>
<label className="form -control-label">data_richiesta</label>
<Form.Control type="text" name="data_richiesta" className="form-control" value={formik.values.data_richiesta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_richiesta && !!formik.errors.data_richiesta}
isValid ={!!formik.touched.data_richiesta && !formik.errors.data_richiesta}
></Form.Control>
{
    formik.errors.data_richiesta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_richiesta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">stato</label>
<Form.Control type="text" name="stato" className="form-control" value={formik.values.stato}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.stato && !!formik.errors.stato}
isValid ={!!formik.touched.stato && !formik.errors.stato}
></Form.Control>
{
    formik.errors.stato && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.stato}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nome</label>
<Form.Control type="text" name="nome" className="form-control" value={formik.values.nome}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome && !!formik.errors.nome}
isValid ={!!formik.touched.nome && !formik.errors.nome}
></Form.Control>
{
    formik.errors.nome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cognome</label>
<Form.Control type="text" name="cognome" className="form-control" value={formik.values.cognome}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cognome && !!formik.errors.cognome}
isValid ={!!formik.touched.cognome && !formik.errors.cognome}
></Form.Control>
{
    formik.errors.cognome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cognome}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">azienda</label>
<Form.Control type="text" name="azienda" className="form-control" value={formik.values.azienda}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.azienda && !!formik.errors.azienda}
isValid ={!!formik.touched.azienda && !formik.errors.azienda}
></Form.Control>
{
    formik.errors.azienda && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.azienda}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
                </>
                
                }
                  

<Form.Group>
<label className="form -control-label">descrizione</label>
<Form.Control as="textarea" rows={5} name="descrizione" className="form-control" value={formik.values.descrizione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.descrizione && !!formik.errors.descrizione}
isValid ={!!formik.touched.descrizione && !formik.errors.descrizione}
></Form.Control>
{
    formik.errors.descrizione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.descrizione}
    </Form.Control.Feedback>
)}
</Form.Group>
{jwtToken != null && jwtToken.data.id == 1 && 
<Form.Group>
<label className="form -control-label">risposta</label>
<Form.Control as="textarea" rows={5}  name="risposta" className="form-control" value={formik.values.risposta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.risposta && !!formik.errors.risposta}
isValid ={!!formik.touched.risposta && !formik.errors.risposta}
></Form.Control>
{
    formik.errors.risposta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.risposta}
    </Form.Control.Feedback>
)}
</Form.Group>
}

                    <Form.Group>
                        <Button type="submit" className="float-left" variant="success">Inserisci Richiesta</Button>
                    </Form.Group>
                </Form>
                <ConfirmationAssistenza 
                buttonNegative="Chiudi"  
                title="Richiesta Assistenza" 
                show={showConfirm} 
                body="" 
                onNegative={() => setShowConfirm(false)} />
            </Card.Body>
        </Card>
        </>
    );
}

