import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Form, Row } from "react-bootstrap";
import { useAppDispatch } from "redux/store";
import Layout from "template";
import LoadingSpinner from "../LoadingSpinner";
import { parseJwtToken } from "services/tokenService";
import { getOneDati_UtentebyidUtente } from "services/dati_utenteService";
import { getAllTipologie_Abbonamento } from "services/tipologie_abbonamentoService";
import { aggiornaOrdine, aggiornaOrdineId, cancellaOrdineId, creaPagamento, getOrdine, getOrdineTest, getPacchettiOrdine } from "services/pagamentoService";
import moment from "moment";
import ConfirmationOrdine from "template/ConfirmationOrdine";


import { useParams } from 'react-router-dom';
import ConfirmationAnnullaOrdine from "template/ConfirmationAnnullaOrdine";
import ConfirmationAggiuntaPacchettoErrore from "template/ConfirmationAggiuntaPacchettoErrore";
import { decodicaEsitoPagamento } from "services/shopService";
import ConfirmationEsitoOrdine from "template/ConfirmationEsitoOrdine";
import { sendMailOrderFailed, sendMailOrderSuccess } from "services/mailService";
import ConfirmationInfoCrediti from "template/ConfirmationInfoCrediti";
import ConfirmationInfoAcquisto from "template/ConfirmationInfoAcquisto";
import { getAllStorico_Pacchetti_Abbonamento_id_user, getStorico_Pacchetti_Abbonamento } from "services/storico_pacchetti_abbonamentoService";
import { addWs_Log_Richieste } from "services/ws_log_richiesteService";
import { addStorico_Sconto, getScontoPresente } from "services/storico_sconto";

interface Params extends Record<string, string>{
  idpk: string;
}

export const Shop: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [datiShop, setDatiShop] = useState(undefined);
  const [datiShopAggiuntive, setDatiShopAggiountive] = useState(undefined);
  const [datiPacchetto, setDatiPacchetto] = useState(undefined);
  const [listaDatiPacchetto, setListaDatiPacchetto] = useState([]);
  const [sommaCosto, setSommaCosto] = useState(0);
  const [sommaCostoIva, setSommaCostoIva] = useState(0);
  const [iva, setIva] = useState(0);
  const [showOrdine, setShowOrdine] = useState(false);
  const [showCarrello, setShowCarrello] = useState(false);
  const [showAnnullaOrdine, setShowAnnullaOrdine] = useState(false);
  const [datiPagamento, setDatiPagamento] = useState(undefined);
  const [datiRiepilogoPagamento, setDatiRiepilogoPagamento] = useState(undefined);
  const [tipoPagamento, setTipoPagamento] = useState(undefined);
  const [idOrdine, setIdOrdine] = useState(undefined);
  const [listaIdpk, setListaIdpk] = useState(undefined);
  const [showControlloPacchetto, setShowControlloPacchetto] = useState(false);

  const [showEsitoOrdine, setShowEsitoOrdine] = useState(false);
  const [datiEsitoOrdine, setDatiEsitoOrdine] = useState(undefined);
  const [messaggioPacchetto, setMessaggioPacchetto] = useState("");
  const [sconto, setSconto] = useState("");
  const [scontoCarrello, setScontoCarrello] = useState(0);

  const [showInfoCrediti, setShowInfoCrediti] = useState(false);
  const [showInfoAcquisto, setShowInfoAcquisto] = useState(false);

  const location = useLocation();

  const { idpk } = useParams<Params>();

  const decode = decodeURIComponent(idpk)

  const [count, setCount] = useState(0);
  const [esitoOrdine, setEsitoOrdine] = useState(undefined);

  const token = localStorage.getItem('token');
  const jwtToken = parseJwtToken(token);

  const [listStoricoPacchetti, setListStoricoPacchetti] = useState(null);

  const pacchettiPrincipali= ["AB1234", "AB1237", "AB1238", "AB1243", "AB1244", "AB1245", "AB1242"]

  const pacchettiPrincipali_ = ["AB12341", "AB12371", "AB12381", "AB12431", "AB12441", "AB12451"]

  //0 utilizzo singolo 1 utilizzo ripetuto
  const lista_sconti: { [key: string]: string[] } = {
    codice: ["MYIMPRESEWELCOME", "MYIMPRESE", "MYIMPRESEWE"],
    valore: ["20_0", "10_1", "15_0"],
  };

  const [addStoricoSconto, setAddStoricoSconto] = useState(null);

  const [addSconto, setAddSconto] = useState(false);
  const [abilitaSconto, setAbilitaSconto] = useState(false);
  const [valSconto, setValSconto] = useState("");

  const [ivaCarrello, setIvaCarrello] = useState(null);
  const [totCarrello, setTotCarrello] = useState(null);

  
  const [pacchettiPrincipaliNome, setPacchettiPrincipaliNome] = useState(null);

  


  const parseIds = (queryString: string) => {
    const ids: string[] = [];
    let currentId: string = "";
  
    for (let i = 0; i < queryString.length; i++) {
      const char = queryString[i];
  
      if (char === "&") {
        // Nuovo ID trovato
        ids.push(currentId);
        currentId = "";
      } else if (char !== "=") {
        // Aggiungi il carattere all'ID corrente
        currentId += char;
      }
    }
  
    // Aggiungi l'ultimo ID
    if (currentId) {
      ids.push(currentId);
    }
  
    return ids;
  }

  const  parseIds_reg = (queryString: string) => {
    const regex = /idpk=(\d+)/g;
    const matches = regex.exec(queryString)?.map((match) => match[1]);
  
    return matches ?? [];
  }

  function extractIdpkParams(url: string): string[] {
    const params = new URLSearchParams(url.split('?')[1]);
    const idpkParams = params.getAll('idpk');
    return idpkParams.map(decodeURIComponent);
  }

  let listapacchetto = []

  const handleServerDelete = async () => {}

  let numero_imprese = 0;
  const getDatiShop = () => {
    
    setIsLoading(true);
    if(jwtToken){
        let idUtente = jwtToken.data.id;

        if(datiShop == null){
          getAllTipologie_Abbonamento(0, 1000).then((response) => {
                if (response && response.data && response.data.document.records) {
                  ////console.log(response);
                  
                setDatiShop(response.data.document.records);
                setDatiShopAggiountive(response.data.document.records);
                setIsLoading(false);

                }else{
                    setIsLoading(false);

                }
              })
        }else{
            setIsLoading(false);
        }
        
    }else{
        setIsLoading(false);
    }
  }

  useEffect(() => {
    
    ////console.log(location.pathname + location.search)
    

    if(listStoricoPacchetti == null){
      if(jwtToken){
        getAllStorico_Pacchetti_Abbonamento_id_user(1, 10000, jwtToken.data.id).then((response) => {
          if (response && response.data && response.data.document && response.data.document.records) {
            setListStoricoPacchetti(response.data.document.records)
          }
        })

      }
    }

    if(datiShop == null)getDatiShop()   
    // getOrdineTest().then((response) => {
    //     ////console.log(response);
    // })
    if(datiShop != null){

      if (location.pathname.includes("annulla")) {
        loadPacchettiPagamento(listaDatiPacchetto, datiShop)
        const urlcomponent = location.pathname.split("/")
        let idordineurl = urlcomponent[3]
        cancellaOrdineId(idordineurl).then((result)=>{
          ////console.log("cancellato")
        })
      }
  
      if (location.pathname.includes("esito")) {
  
        const url = location.pathname + location.search;
        let listaIdpktmp = extractIdpkParams(url)
        setListaIdpk(listaIdpktmp)
        ////console.log(extractIdpkParams(url))
  
        decodicaEsitoPagamento(location.search).then((result) =>{
          ////console.log("-----------------------------")
          ////console.log(result)
          ////console.log("-------------------------------")
          let esito = "KO"
          let messaggio = result
          let stato = 5
          if(result === "OK") esito = "OK"
          if(result === "OK") messaggio = "Ordine concluso con successo! A breve sarà possibile utilizzare i servizi"
          if(result === "OK") stato = 3
          const urlParams = new URLSearchParams(window.location.search);
          const ordine = urlParams.get('ordine');
          // if(stato == 3){
          //   let lista = []
          //   getPacchettiOrdine(ordine).then((result) =>{
  
              
  
          //     result.data.map((pc, i) => {
          //       lista.push(pc.nomeAbbonamento)
          //     })
  
          //     let dati_email = {
          //       "mail": jwtToken.data.email,
          //       "idrif": ordine,
          //       "listapacchetto": lista
          //     }
              
          //     ////console.log("lisa pacchetti")
          //     ////console.log(dati_email)
  
          //     sendMailOrderSuccess(dati_email).then((result)=>{
          //       ////console.log("**************invio*****************")
          //       navigate("/ordini/", {replace: true})
          //     })
          //   })
            
          // }
          
          //sif(esito === "KO") setShowAnnullaOrdine(true)
  
          
          aggiornaOrdine(ordine, stato, listaIdpktmp).then((result) =>{
            
            if(stato == 3){
              let lista = []
              getPacchettiOrdine(ordine).then((result) =>{
    
                
    
                result.data.map((pc, i) => {
                  lista.push(pc.nomeAbbonamento)
                })
    
                let dati_email = {
                  "mail": jwtToken.data.email,
                  "idrif": ordine,
                  "listapacchetto": lista
                }
                
                ////console.log("lisa pacchetti")
                ////console.log(dati_email)
    
                sendMailOrderSuccess(dati_email).then((result)=>{
                  //console.log("**************invio*****************")
                  //navigate("/ordini/", {replace: true})
                })

                if(stato == 3){
                  let data_richiesta = {
                    data_richiesta: getItalianTimestamp(),
                    esito: 'OK',
                    username: jwtToken.data.id,
                    servizio: 'ORDINE',
                    richiesta: 'Lista pacchetti idorder: ' + ordine +" lista: " + lista,
                    errore: 'Nessun Errore'
                  }
                  addWs_Log_Richieste(data_richiesta).then((result)=>{
                    //console.log("************req ok*****************")
                  })
                  if(addStoricoSconto != null){
                    addStorico_Sconto(addStoricoSconto).then((response)=>{
                      ////console.log("sconto creato")
                    })
                  }
                  
    
                }

                

              })
              
            }
            if(stato == 2 || stato == 5){
              let dati_email = {
                "mail": jwtToken.data.email,
                "idrif": ordine
              }
              sendMailOrderFailed(dati_email).then((result)=>{
                ////console.log(result)
                //navigate("/ordini/", {replace: true})
              })

              let data_richiesta = {
                data_richiesta: getItalianTimestamp(),
                esito: 'OK',
                username: jwtToken.data.id,
                servizio: 'ORDINE',
                richiesta: 'Lista pacchetti' + ordine,
                errore: 'Nessun Errore'
              }
              addWs_Log_Richieste(data_richiesta).then((result)=>{
                //console.log("************req ko*****************")
              })


            }
            setEsitoOrdine(result)
            let dati = {
              "esito": esito,
              "messaggio": messaggio
            }
    
            let stato_ordine = {
              "stato_ordine": stato
            }
  
            
            setDatiEsitoOrdine(dati)
            setShowEsitoOrdine(true)

            
            
            
          })
          
          
        })
        
      } else {
        setEsitoOrdine("")
      }

    }
    

  }, [datiShop, listStoricoPacchetti]);

  const handleVaiOridini = () => {
    navigate("/ordini/", {replace: true})
    setShowEsitoOrdine(false)

  }

  const getItalianTimestamp = (): string => {
    // Crea una data e imposta il fuso orario italiano (CET/CEST)
    const now = new Date();
    
    // Formatta la data nel formato richiesto (YYYY-MM-DD HH:MM:SS)
    // Aggiungendo +1 ora per il fuso orario italiano
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Europe/Rome',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    
    const formatter = new Intl.DateTimeFormat('it-IT', options);
    const parts = formatter.formatToParts(now);
    
    // Estrai le parti della data
    const year = parts.find(part => part.type === 'year')?.value;
    const month = parts.find(part => part.type === 'month')?.value;
    const day = parts.find(part => part.type === 'day')?.value;
    const hour = parts.find(part => part.type === 'hour')?.value;
    const minute = parts.find(part => part.type === 'minute')?.value;
    const second = parts.find(part => part.type === 'second')?.value;
    
    // Formatta nel formato richiesto YYYY-MM-DD HH:MM:SS
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const loadPacchettiPagamento = (listaDatiPacchetto, datiShop) =>{

    const url = location.pathname + location.search; // Combine pathname and search
    ////console.log(extractIdpkParams(url))
    let listaIdpktmp = extractIdpkParams(url)
    setListaIdpk(listaIdpktmp)
    
    if((listaDatiPacchetto != null && listaDatiPacchetto.length > 0) || count === 0){
      let listaIdReturn = []

      let somma = 0//sommaCostoIva;
      let iva = 0
      let sommaiva = 0
      let sommaTotale = 0;
      listaIdpktmp.forEach(id =>{
        if(datiShop != null){
          datiShop.map((dati, x) =>{
            if(id === dati.codice_abbonamento) {
              listaIdReturn.push(dati)
              sommaTotale += somma + Number(dati.costo)
              sommaiva = sommaTotale * 1.22
              iva = sommaiva - sommaTotale

            }
          })
          setListaDatiPacchetto(listaIdReturn)
          setSommaCostoIva(sommaiva)
          setSommaCosto(sommaTotale)
          setIva(iva)
          setListaIdpk(null)
          setCount(2)
          setShowAnnullaOrdine(true)
          
        }
      })
    }


  }

  const valoreDato = (dato) => {
    if(dato == null || dato === ""){
        return "Dato non fornito"
    }else{
        return dato
    }
}

const handleAggiungiPacchetto = (pacchetto) => {

    
    let messagioerrore = "Non puoi aggiungere questo pacchetto perchè è già presente nella lista di pacchetti scelti!"
    let elementoTrovato = listaDatiPacchetto.find(item => item === pacchetto);

    if(scontoCarrello > 0){
      messagioerrore = "Hai aggiornato il carrello dopo l'applicazione del codice sconto! Per usufruire dello sconto devi inserire il codice sconto di nuovo. Grazie"
      
      setSconto("")
      setAddStoricoSconto(null)
      setValSconto("")
      setAddSconto(false)
      setMessaggioPacchetto(messagioerrore)
      setShowControlloPacchetto(true)
    }

    setScontoCarrello(0)


    if (!elementoTrovato){
      if(listaDatiPacchetto.length > 0){
        listaDatiPacchetto.forEach(element => {
          if(pacchetto.codice_abbonamento === "AB1234" ||  pacchetto.codice_abbonamento === "AB1237" || pacchetto.codice_abbonamento === "AB1243" || pacchetto.codice_abbonamento === "AB1245" ||
          pacchetto.codice_abbonamento === "AB1238"){
            messagioerrore = "Non puoi aggiungere questo pacchetto perchè è già presente un pacchetto principale!"
            elementoTrovato = true
          }   
        });
      }
    }
    

    if (elementoTrovato) {
      setMessaggioPacchetto(messagioerrore)
      setShowControlloPacchetto(true)
    } else {
      let hapacchetto = false
      if(listStoricoPacchetti != null){
        // listStoricoPacchetti.some(pacSto=>{
        //   if(pacchettiPrincipali.includes("AB" + pacSto.id_pacchetto)){
        //     hapacchetto = true
        //     return hapacchetto
        //   }
        // })
        let trovato = listStoricoPacchetti.find(item => 
          pacchettiPrincipali.includes("AB" + item.id_pacchetto)
        );
        if(trovato){
          hapacchetto = true
        }
      }
      if(pacchettiPrincipali.includes(pacchetto.codice_abbonamento)) hapacchetto = true
      if(hapacchetto == false){
        let messagioerrore = "Non puoi aggiungere questo pacchetto perchè non hai un pacchetto principale attivo o un pacchetto ricerca imprese"
        setMessaggioPacchetto(messagioerrore)
        const pPrincipali = [
          "MyImprese Premium",
          "MyImprese Gold",
          "MyImprese Silver",
          "MyImprese Start",
          "MyImprese Basic",
          "MyVisure Essential",
          "Pacchetto Ricerca Imprese",
        ]
        setPacchettiPrincipaliNome(pPrincipali)
        setShowControlloPacchetto(true)

      }else{

        setListaDatiPacchetto([...listaDatiPacchetto, pacchetto]);
        let somma = sommaCosto;
        let sommaTotale = somma + Number(pacchetto.costo)

        let iva = (sommaTotale * 1.22) - sommaTotale

        setIva(iva)
        setSommaCosto(sommaTotale)
        setSommaCostoIva(sommaTotale + iva)
        setShowCarrello(true)

        setIvaCarrello(iva)
        setTotCarrello(sommaTotale + iva)

        setTimeout(() => {
          const targetDiv = document.getElementById("carrello");
          if (targetDiv) {
              targetDiv.scrollIntoView({
                  behavior: 'smooth', // Scroll fluido
                  block: 'start',     // Allineamento in cima
              });
          }
        }, 0); // Il timeout di 0 ms fa in modo che lo scroll avvenga subito dopo il prossimo ciclo di eventi


      }
      
    }
}

const handleServizioPagamento = (id) => {
  if (id === null) id = idOrdine;

  // Creiamo un Set per evitare duplicati
  let listaIdSet = new Set();

  listaDatiPacchetto.forEach((elemento) => {
    listaIdSet.add(elemento.codice_abbonamento);
  });

  // Convertiamo il Set di nuovo in un array
  let listaId = Array.from(listaIdSet);

  creaPagamento(id, listaId).then((response) => {
    window.open(response.data, "_self");
  });
};


const handleChiudiCarrello = () =>{
  setShowCarrello(false)
  setListaDatiPacchetto([])
  setSommaCosto(0)
  setSommaCostoIva(0)
  setIva(0)
  setScontoCarrello(0)
  ////console.log("elimina carrello")
  setIvaCarrello(null)
  setTotCarrello(null)
  setSconto("")
  setAbilitaSconto(false)
  setValSconto("")
  setAddSconto(false)
  setAddStoricoSconto(null)

}


const handlePagaPaypal = (id) => {
  setIsLoading(true);
  let ordine = {
    data: moment(),
    metodoDiPagamento:"paypal",
    iva: iva,
    prezzoTotale: sommaCostoIva,
    pianoAbbonamento: "",
    statoOrdine: 0,
    id_login: jwtToken.data.id,
    id_utente: jwtToken.data.id,
    datiPacchetto: listaDatiPacchetto
  }

  getOrdine(ordine).then((response) => {
    if(response.status === 200){
      let data = {
        "datiPacchetto": listaDatiPacchetto,
        "costoTotale": sommaCostoIva,
        "iva": iva,
        "sconto": scontoCarrello
      }
      setIdOrdine(response.data.id)
      setDatiRiepilogoPagamento(data)
      setShowOrdine(true);
      setIsLoading(false);
    }else{
      setIsLoading(false);
    }
  })
}

const handlePagaNexi = (id) => {
  setIsLoading(true);
  let ordine = {
    data: moment(),
    metodoDiPagamento:"nexi",
    iva: iva,
    prezzoTotale: sommaCostoIva,
    pianoAbbonamento: "",
    statoOrdine: 0,
    id_login: jwtToken.data.id,
    id_utente: jwtToken.data.id,
    datiPacchetto: listaDatiPacchetto
  }

  getOrdine(ordine).then((response) => {
    if(response.status === 200){
      let data = {
        "datiPacchetto": listaDatiPacchetto,
        "costoTotale": sommaCostoIva,
        "iva": iva,
        "sconto": scontoCarrello
      }
      ////console.log(listaDatiPacchetto)
      setIdOrdine(response.data.id)
      setDatiRiepilogoPagamento(data)
      setShowOrdine(true);
      setIsLoading(false);
    }else{
      setIsLoading(false);
    }
  })

  //navigate("/ws_login/", {replace: true})
}

const eliminaElemento = (pacchetto, index) => {
  // Crea una copia della lista senza l'elemento da eliminare
  const nuovaLista = listaDatiPacchetto.filter((_, i) => i !== index);
  // Aggiorna lo stato con la nuova lista
  setListaDatiPacchetto(nuovaLista);
  let somma = sommaCosto;
  let sommaTotale = somma - Number(pacchetto.costo)

  let iva = (sommaTotale * 1.22) - sommaTotale

  setIva(iva)
  setSommaCostoIva(sommaTotale + iva)
  setSommaCosto(sommaTotale)
  setScontoCarrello(0)
  setIvaCarrello(iva)
  setTotCarrello(sommaTotale + iva)
  setAbilitaSconto(false)
  setSconto("")
  setValSconto("")
  setAddStoricoSconto(null)

  setAddSconto(false)

  if(nuovaLista.length === 0){
    setShowCarrello(false)
    setListaDatiPacchetto([])
    setSommaCosto(0)
    setSommaCostoIva(0)
    setIva(0)
  }

  if(scontoCarrello > 0){
    let messagioerrore = "Hai aggiornato il carrello dopo l'applicazione del codice sconto! Per usufruire dello sconto devi inserire il codice sconto di nuovo. Grazie"
    setMessaggioPacchetto(messagioerrore)
    setShowControlloPacchetto(true)
    
    setSconto("")
    setValSconto("")
    setAddSconto(false)
    setScontoCarrello(0)
    setAddStoricoSconto(null)
  }
};

const getCurrentTime = () => {
  const nowItalia = new Date().toLocaleString("sv-SE", { timeZone: "Europe/Rome" });

  // Converte direttamente la data senza problemi di formato
  const date = new Date(nowItalia);

  const formattedDate =
    date.getFullYear() + "-" +
    String(date.getMonth() + 1).padStart(2, "0") + "-" +
    String(date.getDate()).padStart(2, "0") + " " +
    String(date.getHours()).padStart(2, "0") + ":" +
    String(date.getMinutes()).padStart(2, "0") + ":" +
    String(date.getSeconds()).padStart(2, "0");

  ////console.log(formattedDate);
  return formattedDate;
};


  

const handleSconto = (valoreScontoInput) => {

    
    if(valoreScontoInput == 1){
      setAddSconto(false)
      setValSconto("")
      setScontoCarrello(0)
      setAbilitaSconto(false)
      setSommaCostoIva(totCarrello)
      setIva(ivaCarrello)
      setSconto("")
      setAddStoricoSconto(null)




    }else{
      ////console.log("Sconto:" + sconto);
    if (lista_sconti["codice"].includes(sconto)) {
      let valore_sconto = 0
      let uso_sconto = 0

      getScontoPresente(jwtToken.data.id, sconto).then((response) => {

        if((!response.data.present && response.data.tipo_utilizzo == null ) || (response.data.present && response.data.tipo_utilizzo != 0 ) ){
      
          const indice = lista_sconti["codice"].indexOf(sconto);

          let val_sconto_tmp = lista_sconti["valore"][indice]
          let valore_sconto_data_split = val_sconto_tmp.split("_");

          valore_sconto = parseInt(valore_sconto_data_split[0], 10);
          uso_sconto = parseInt(valore_sconto_data_split[1], 10);

          
          //console.log("Valore sconto:", valore_sconto);
          //console.log("Valore uso:", uso_sconto);


          setAddSconto(true)
          setValSconto(valore_sconto.toString())

          //console.log("Totale iniziale (IVA inclusa):", sommaCostoIva);

          // Scorporo dell'IVA
          let imponibile = sommaCostoIva / 1.22;
          let iva = sommaCostoIva - imponibile;

          //console.log("Imponibile iniziale:", imponibile.toFixed(2));
          //console.log("IVA iniziale:", iva.toFixed(2));

          // Applicare lo sconto del 20%
          let imponibileScontato = imponibile * ((100 - valore_sconto)/100); // (100% - 20% = 80%)
          let ivaScontata = imponibileScontato * 0.22; // Calcolo IVA sul nuovo imponibile

          // Calcolare il nuovo totale (IVA inclusa)
          let costoIvaInclusaScontato = imponibileScontato + ivaScontata;

          let scontoTotale = imponibile - imponibileScontato

          //console.log("Imponibile scontato:", imponibileScontato.toFixed(2));
          //console.log("IVA scontata:", ivaScontata.toFixed(2));
          //console.log("Totale scontato (IVA inclusa):", costoIvaInclusaScontato.toFixed(2));
          //console.log("Sconto totale:", scontoTotale.toFixed(2));


          setSommaCostoIva(costoIvaInclusaScontato)
          setIva(ivaScontata)
          setScontoCarrello(scontoTotale)
      
    }else {
      setMessaggioPacchetto("Il codice sconto inserito è già stato utilizzato, oppure il numero di utilizzi consentito è stato superato")
      setShowControlloPacchetto(true)
    }

    let data_sconto = {
      "codice" :sconto,
      "valore": valore_sconto,
      "tipo_utilizzo": uso_sconto,
      "id_utente": jwtToken.data.id,
      "data": getCurrentTime()

    }

    // addStorico_Sconto(data_sconto).then((response)=>{
    //   //console.log("sconto creato")
    // })

    setAddStoricoSconto(data_sconto)
    
    }
  
  )
    
      
    }else{
      setMessaggioPacchetto("Codice Sconto non trovato o non corretto! Controlla il codice e riprova")
      setShowControlloPacchetto(true)
    }

    }
};

  
  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid  paddin-left-0" >
      {((listaDatiPacchetto != null && listaDatiPacchetto.length > 0) || showCarrello) && 
      <div className="d-flex flex-column" id="carrello">
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-11">
                    <h5>Pacchetti Scelti</h5>
                </div>
                <div className="col-sm-1 cursor-pointer">
                  <i onClick={handleChiudiCarrello} className="fa-solid fa-trash color-gray"></i>
                </div>
          </Row>
          <div className="d-flex flex-column" >
          <Row className="margin-bottom-20">
            <div className="col-sm-9">
            <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
            {listaDatiPacchetto != null && listaDatiPacchetto.map((pacchetto, i) =>{
                return(
                <>
                  <div className="row w-100 carrello">
                    <div className="col-sm-1 text-center cursor-pointer">
                      <i onClick={() => eliminaElemento(pacchetto, i)} className="fa-solid fa-trash color-gray"></i>
                    </div>
                    <div className="col-sm-9">
                        <h6 className="posizioni-lista">{pacchetto.nome_abbonamento}</h6>
                    </div>
                    <div className="col-sm-2 car-2">
                        <h6 className="boldtesto">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</h6>
                    </div>

                  </div>
                  
              </>
           )
            
          })  
        }
        {scontoCarrello != 0 &&
          <div className="row w-100 cost">
            <div className="col-sm-8 text-align-right font-12 d-flex align-items-center">
              {/* Codice Sconto {sconto} */}
              </div>
            <div className="col-sm-2 posizioni-lista cost-iva color-gray-pg">Sconto</div>
            <div className="col-sm-2 posizioni-lista color-gray-pg"><strong>€ {Number(scontoCarrello).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</strong></div>
        </div>
        }
        
        <div className="row w-100 cost">
            <div className="col-sm-8"></div>
            <div className="col-sm-2 posizioni-lista cost-iva">Iva</div>
            <div className="col-sm-2 posizioni-lista"><strong>€ {Number(iva).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</strong></div>
        </div>
        <div className="row w-100 cost">
            <div className="col-sm-8"></div>
            <div className="col-sm-2 posizioni-lista cost-iva">Totale iva inclusa</div>
            <div className="col-sm-2 posizioni-lista font-size-large"><strong>€ {Number(sommaCostoIva).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</strong></div>
        </div>
        {abilitaSconto && 
        <div className="row w-100 cost d-flex align-items-center">
        {/* <div className="col-sm-1 posizioni-list"></div> */}
        <div className="col-sm-1 cursor-pointer text-center cursor-pointer">
           <i onClick={() => handleSconto(1)} className="fa-solid fa-trash color-gray cursor-pointer"></i>
        </div>
        <div className="col-sm-1 posizioni-list d-flex align-items-center p-rl-0">
            <span>Codice</span>
        </div>
        
        <div className="col-sm-4 posizioni-lista d-flex align-items-center p-rl-0">
            <Form.Group className="w-100 m-bottom-0">
                <Form.Control 
                    type="text" 
                    name="telefono" 
                    className="form-control " 
                    value={sconto} 
                    onChange={(e) => setSconto(e.target.value)}
                />
            </Form.Group>
        </div>
        
        <div className="col-sm-2 posizioni-lista d-flex align-items-center">
            <Button 
                variant="success" 
                disabled={sconto.length < 3 || addSconto} 
                onClick={() => handleSconto(0)}
            >
                Applica
            </Button>
        </div>
        {valSconto != "" &&
          <div className="col-sm-4 font-12">
            Sconto {sconto} del {valSconto}%
          </div>
        }
        

    </div>
        
        }
        {!abilitaSconto &&
        <div className="row w-100 cost d-flex align-items-center">
        {/* <div className="col-sm-1 posizioni-list"></div> */}
        
        <div className="col-sm-6 col-xs-12 posizioni-list d-flex align-items-center crediti cursor-pointer"
          onClick={()=>setAbilitaSconto(true)}
        >
            <span >*hai un codice sconto? clicca qui</span>
        </div>
          </div>
        
        
        }
        



        </div>
        </div>
        <div className="col-sm-3">
          <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero margin-btn-pay">
            <>
            <div className="col-sm-12 center-block-custom">
            <a  className="w-100 display-block btn-paypal" 
                                            
                                            onClick={() => handlePagaPaypal(null)}>
                                                <i className="fa-brands fa-paypal"></i> Paga con PayPal</a>
            </div>
            <div className="col-sm-12 center-block-custom">
                                            <a  className="w-100 display-block btn-nexi" 
                                            
                                            onClick={() => handlePagaNexi(null)}>
                                                <i className="fa-solid fa-credit-card"></i> Paga con Carta</a>
             </div>
                                            
            </>
          </div>
        </div>
          </Row>
        </div>
        </div>
      }

      <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco heigth-max ">
                <div className="col-sm-8">
                    <h4>Pacchetti Principali</h4>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <p className="crediti cursor-pointer" onClick={()=> setShowInfoAcquisto(true)}>* come acquistare?</p>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <p className="crediti cursor-pointer" onClick={()=> setShowInfoCrediti(true)}>* cosa sono i crediti?</p>
                </div>
                
          </Row>
        </div>
        <div className="d-flex flex-column" >
          <Row className="margin-bottom-20">
          {datiShop != null && datiShop.map((pacchetto, i) =>{
            if(pacchetto.codice_abbonamento === "AB1234" 
              || pacchetto.codice_abbonamento === "AB1237" 
              || pacchetto.codice_abbonamento === "AB1238"
              || pacchetto.codice_abbonamento === "AB1243"
              || pacchetto.codice_abbonamento === "AB1244"
              || pacchetto.codice_abbonamento === "AB1245"
            ){
              return(
              <>
                <div className="col-sm-4 margin-bottom-20">
                  <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                  <div className="col-sm-12">
                                            <h5 className="posizioni-lista cursor-pointer">{pacchetto.nome_abbonamento}</h5>
                                            {pacchetto.codice_abbonamento === "AB1234" &&
                                            <span className="box-piani-span">Il più acquistato dai professionisti e studi</span>
                                            }
                                            
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12 min-height-250">
                                            <p><strong >Cosa contiene:</strong></p>
                                                <ul>
                                                    {pacchetto.ricercaunica > 0 && 
                                                    <li>Ricerca Imprese: <strong>{pacchetto.ricercaunica}</strong></li>
                                                    }
                                                    {pacchetto.dettaglio > 0 && 
                                                    <li>Dettaglio Imprese: <strong>{pacchetto.dettaglio}</strong></li>
                                                    }
                                                    {pacchetto.anagrafica > 0 && 
                                                    <li>Anagrafiche in File: <strong>{pacchetto.anagrafica}</strong></li>
                                                    }
                                                    {pacchetto.visure > 0 && 
                                                    <li>Visure Camerali: <strong>{pacchetto.visure}</strong></li>
                                                    }
                                                    {pacchetto.bilanci > 0 && 
                                                    <li>Bilanci Azienda: <strong>{pacchetto.bilanci}</strong></li>
                                                    }
                                                    {pacchetto.protesti > 0 && 
                                                    <li>Protesti: <strong>{pacchetto.protesti}</strong></li>
                                                    }
                                                    {pacchetto.catastali > 0 && 
                                                    <li>Visure Catastali: <strong>{pacchetto.catastali}</strong></li>
                                                    }
                                                    {pacchetto.reportsmart > 0 && 
                                                    <li>Report Smart: <strong>{pacchetto.reportsmart}</strong></li>
                                                    }
                                                    {pacchetto.deepdossier > 0 && 
                                                    <li>Report Azienda Base: <strong>{pacchetto.deepdossier}</strong></li>
                                                    }
                                                    {pacchetto.executivereport > 0 && 
                                                    <li>Report Azienda Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                    {pacchetto.reportpersona > 0 && 
                                                    <li>Report Persona Base: <strong>{pacchetto.reportpersona}</strong></li>
                                                    }
                                                    {pacchetto.reportpersonacompleto > 0 && 
                                                    <li>Report Persona Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-6"><h5 className="posizioni-lista">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })} <span className="font-size-small"> + Iva</span></h5></div>
                                        <div className="col-md-6 align-right">
                                            <Button variant="success" className="" 
                                            disabled={false} 
                                            onClick={() => handleAggiungiPacchetto(pacchetto)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                        </div>
                  </div>
                </div>
              </>
           )
          }
            
          })  
        }
          </Row>
          {showInfoCrediti && 
                  <ConfirmationInfoCrediti buttonNegative="Chiudi" onNegative={() => setShowInfoCrediti(false)} title={"Crediti MyImprese"} />
                }
          {showInfoAcquisto &&
            <ConfirmationInfoAcquisto buttonNegative="Chiudi" onNegative={() => setShowInfoAcquisto(false)} title={"Come Acquistare"} />
          }
        </div>
        <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h5>Pacchetti Aggiuntivi</h5>
                </div>
          </Row>
        </div>
        <div className="d-flex flex-column" >
        <Row className="margin-bottom-20">
          {datiShopAggiuntive != null && datiShopAggiuntive.map((pacchetto, i) =>{
            if(pacchetto.codice_abbonamento != "AB1234" 
              && pacchetto.codice_abbonamento != "AB1237" 
              && pacchetto.codice_abbonamento != "AB1238"
              && pacchetto.codice_abbonamento != "AB1243"
              && pacchetto.codice_abbonamento != "AB1244"
              && pacchetto.codice_abbonamento != "AB1245"
              && pacchetto.codice_abbonamento != "AB1246"
              && pacchetto.codice_abbonamento != "AB1247"
              && pacchetto.codice_abbonamento != "AB1248"
              && pacchetto.codice_abbonamento != "AB1249"
              && pacchetto.codice_abbonamento != "AB1250"
              && pacchetto.codice_abbonamento != "AB1251"
            ){
            return(
              <>
              <div className="col-sm-4 margin-bottom-20">
              <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                  <div className="col-sm-12">
                                            <h5 className="pacchetto-aggiuntivo cursor-pointer">{pacchetto.nome_abbonamento}</h5>
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12 ">
                                            <p><strong>Cosa contiene:</strong></p>
                                                <ul>
                                                {pacchetto.ricercaunica > 0 && 
                                                    <li>Ricerca Imprese: <strong>{pacchetto.ricercaunica}</strong></li>
                                                    }
                                                    {pacchetto.dettaglio > 0 && 
                                                    <li>Dettaglio Imprese: <strong>{pacchetto.dettaglio}</strong></li>
                                                    }
                                                    {pacchetto.anagrafica > 0 && 
                                                    <li>Anagrafiche in File: <strong>{pacchetto.anagrafica}</strong></li>
                                                    }
                                                    {pacchetto.visure > 0 && 
                                                    <li>Visure Camerali: <strong>{pacchetto.visure}</strong></li>
                                                    }
                                                    {pacchetto.bilanci > 0 && 
                                                    <li>Bilanci Azienda: <strong>{pacchetto.bilanci}</strong></li>
                                                    }
                                                    {pacchetto.protesti > 0 && 
                                                    <li>Protesti: <strong>{pacchetto.protesti}</strong></li>
                                                    }
                                                    {pacchetto.catastali > 0 && 
                                                    <li>Visure Catastali: <strong>{pacchetto.catastali}</strong></li>
                                                    }
                                                    {pacchetto.reportsmart > 0 && 
                                                    <li>Report Smart: <strong>{pacchetto.reportsmart}</strong></li>
                                                    }
                                                    {pacchetto.deepdossier > 0 && 
                                                    <li>Report Azienda Base: <strong>{pacchetto.deepdossier}</strong></li>
                                                    }
                                                    {pacchetto.executivereport > 0 && 
                                                    <li>Report Azienda Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                    {pacchetto.reportpersona > 0 && 
                                                    <li>Report Persona Base: <strong>{pacchetto.reportpersona}</strong></li>
                                                    }
                                                    {pacchetto.reportpersonacompleto > 0 && 
                                                    <li>Report Persona Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-6"><h5 className="pacchetto-aggiuntivo">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })} <span className="font-size-small"> + Iva</span></h5></div>
                                        <div className="col-md-6 align-right">
                                            <Button variant="info" className="" 
                                            disabled={false} 
                                            onClick={() => handleAggiungiPacchetto(pacchetto)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                            {"     "}
                                            {/* <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button> */}
                                        </div>
                  </div>
              </div>
            </>
            )
            }
          })
           
        }
           </Row>
        </div>
        <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h5>Servizi Singoli</h5>
                </div>
          </Row>
        </div>
        <div className="d-flex flex-column" >
        <Row className="margin-bottom-20">
          {datiShopAggiuntive != null && datiShopAggiuntive.map((pacchetto, i) =>{
            if(pacchetto.codice_abbonamento != "AB1234" 
              && pacchetto.codice_abbonamento != "AB1237" 
              && pacchetto.codice_abbonamento != "AB1238"
              && pacchetto.codice_abbonamento != "AB1243"
              && pacchetto.codice_abbonamento != "AB1244"
              && pacchetto.codice_abbonamento != "AB1245"
              && pacchetto.codice_abbonamento != "AB1235"
              && pacchetto.codice_abbonamento != "AB1236"
              && pacchetto.codice_abbonamento != "AB1239"
              && pacchetto.codice_abbonamento != "AB1240"
              && pacchetto.codice_abbonamento != "AB1241"
              && pacchetto.codice_abbonamento != "AB1242"
            ){
            return(
              <>
              <div className="col-sm-4 margin-bottom-20">
              <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                  <div className="col-sm-12">
                                            <h5 className="pacchetto-aggiuntivo cursor-pointer">{pacchetto.nome_abbonamento}</h5>
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12 ">
                                            <p><strong>Cosa contiene:</strong></p>
                                                <ul>
                                                {pacchetto.ricercaunica > 0 && 
                                                    <li>Ricerca Imprese: <strong>{pacchetto.ricercaunica}</strong></li>
                                                    }
                                                    {pacchetto.dettaglio > 0 && 
                                                    <li>Dettaglio Imprese: <strong>{pacchetto.dettaglio}</strong></li>
                                                    }
                                                    {pacchetto.anagrafica > 0 && 
                                                    <li>Anagrafiche in File: <strong>{pacchetto.anagrafica}</strong></li>
                                                    }
                                                    {pacchetto.visure > 0 && 
                                                    <li>Visure Camerali: <strong>{pacchetto.visure}</strong></li>
                                                    }
                                                    {pacchetto.bilanci > 0 && 
                                                    <li>Bilanci Azienda: <strong>{pacchetto.bilanci}</strong></li>
                                                    }
                                                    {pacchetto.protesti > 0 && 
                                                    <li>Protesti: <strong>{pacchetto.protesti}</strong></li>
                                                    }
                                                    {pacchetto.catastali > 0 && 
                                                    <li>Visure Catastali: <strong>{pacchetto.catastali}</strong></li>
                                                    }
                                                    {pacchetto.reportsmart > 0 && 
                                                    <li>Report Smart: <strong>{pacchetto.reportsmart}</strong></li>
                                                    }
                                                    {pacchetto.deepdossier > 0 && 
                                                    <li>Report Azienda Base: <strong>{pacchetto.deepdossier}</strong></li>
                                                    }
                                                    {pacchetto.executivereport > 0 && 
                                                    <li>Report Azienda Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                    {pacchetto.reportpersona > 0 && 
                                                    <li>Report Persona Base: <strong>{pacchetto.reportpersona}</strong></li>
                                                    }
                                                    {pacchetto.reportpersonacompleto > 0 && 
                                                    <li>Report Persona Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-6"><h5 className="pacchetto-aggiuntivo">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })} <span className="font-size-small"> + Iva</span></h5></div>
                                        <div className="col-md-6 align-right">
                                            <Button variant="info" className="" 
                                            disabled={false} 
                                            onClick={() => handleAggiungiPacchetto(pacchetto)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                            {"     "}
                                            {/* <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button> */}
                                        </div>
                  </div>
              </div>
            </>
            )
            }
          })
           
        }
           </Row>
        </div>
      </div>
      <ConfirmationOrdine
          buttonNegative="Chiudi" 
          buttonPositive="Conferma" 
          title="Acquisto Pacchetto" 
          show={showOrdine} 
          body={datiRiepilogoPagamento} 
          onNegative={() => setShowOrdine(false)}
          onPositive={() => handleServizioPagamento(idOrdine)}  
      />

      <ConfirmationAnnullaOrdine
          buttonNegative="Chiudi" 
          //buttonPositive="Conferma" 
          title="Annulla Acquisto Pacchetto" 
          show={showAnnullaOrdine} 
          body="Il tuo ordine è stato annullato, per proseguire effettua di nuovo il pagamento" 
          onNegative={() => setShowAnnullaOrdine(false)}
          //onPositive={() => handleServizioPagamento(idOrdine)}  
      />
      <ConfirmationAggiuntaPacchettoErrore
          buttonNegative="Chiudi" 
          title="Controllo presenza pacchetti" 
          show={showControlloPacchetto} 
          body={messaggioPacchetto}
          listPacchetti={pacchettiPrincipaliNome}
          onNegative={() => setShowControlloPacchetto(false)} 
      />
      <ConfirmationEsitoOrdine
        buttonNegative="Chiudi" 
        title="Esito Ordine" 
        show={showEsitoOrdine} 
        body={datiEsitoOrdine}
        onNegative={()=> handleVaiOridini()}
        disableButton={false}
      />
      </Layout >
  );
};

