import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setAssistenzaMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { addAssistenza, inserisciAssistenza, updateAssistenza } from "services/assistenzaService";
import { parseJwtToken } from "services/tokenService";
import { getOneWs_Login } from "services/ws_loginService";
import moment from "moment";
import { sendMailAssistenza } from "services/mailService";

import img_jmyimprese from "../asset/Intersect.png"
import img_logotecno from "../asset/TecnoinnovisLogo.png"
import img_logomyimprese from "../asset/Logomyimprese.png"
import img_logomyvisure from "../asset/logo_myvisure.png"
import img_logopaypal from "../asset/logo_pp.png"
import img_logonexi from "../asset/logo_n.png"
import img_logomyimpresawhite from "../asset/logo_mi_white.png"
import logo from "../asset/logo_myimprese.png"
import { useNavigate } from "react-router";
import NavigationMenu from "template/NavigationMenu";
import FooterPage from "template/FooterPage";
import PrezziResponsiveNegozioPrincipali from "components/prezzi_responsive_negozio_principali";
import PrezziResponsiveNegozioAggiuntivi from "components/prezzi_responsive_negozio_aggiuntivi";
import PrezziResponsiveNegozioSingoli from "components/prezzi_responsive_negozio_aggiuntivi";


type Props = {
    row?: any,
    // hideShowForm: (actionName) => void;
    // getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Negozio: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const iValue={id:'',id_utente:'',nome:'',cognome:'',azienda:'',email:'',telefono:'',descrizione:'', acceptTerms: false};
    //let initialValues = iValue
   
    

    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    var idUtente = 0
    let iValueUser = {
        id: '',
        id_utente: '',
        nome: '',
        cognome: '',
        azienda: '',
        email: '',
        telefono: '',
        descrizione: '',
        acceptTerms: false
    }
    const iValueEmpty = {
        id: '',
        id_utente: '',
            nome: '',
            cognome: '',
            azienda: '',
            email: '',
            telefono: '',
            descrizione: '',
            acceptTerms:false
    }

    const[datiLoad, setDatiLoad] = useState(null);

    const[id_utente, setIdUtente] = useState('');
    const[nome, setNome] = useState('');
    const[cognome, setCognome] = useState('');
    const[azienda, setAzienda] = useState('');
    const[email, setEmail] = useState('');
    const[telefono, setTelefono] = useState('');
    const[descrizione, setDescrizione] = useState('');

    const[messaggio, setMessaggio] = useState("")
    const[esito, setEsito] = useState(false)

    
    const videoPath_comefunziona = require("../asset/come_funziona_myimprese.mp4");
    const videoPath_presentazione = require("../asset/myimprese_presentazione_video.mp4");

    useEffect(() => {
        
        if(jwtToken && jwtToken.data != null ){
            idUtente = jwtToken.data.id;
        }else{
            iValueUser = iValueEmpty
        }
      }, [datiLoad]);
    
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])



    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLoging = () => {
    navigate('/', { replace: true });
  }



  const handleRegister = () => {
    navigate('/registrazione', { replace: true });
}
const handleConatti = () => {
  navigate('/assistenza', { replace: true });
}



    return (
        <>
        <div className="">
            <NavigationMenu isLoading={false}/>
            <Row className="justify-content-center">
              <div className="col-xl-9 text-center p-top-50">
                    
                        <h1 className="p-bottom-50">Servizi e Soluzioni MyImprese</h1>
                        <Row>
                          <div className="col-sm-6 col-xs-12 p-bottom-50   ">
                            <Row className="shadow shadow-margin-zero box-negozio-info p-top-30">
                            <div className='col-sm-12'>
                                <p className='color-blue d-flex align-items-center'>
                                  <i className="fas fa-fw fa-search-location font-20 me-2 p-right-12"></i> 
                                  Ricerca Impresa
                                </p>
                              </div>
                              <div className='col-sm-12'>
                                  <p className='box-negozio-text'>Trova le aziende che ti interessano per <strong>area geografica, dimensione, fatturato, tipo azienda, settore, presenza sul mercato</strong> e tanto altro</p>
                              </div>
                            </Row>
                          </div>
                          <div className="col-sm-6 col-xs-12 p-bottom-50 ">
                          <Row className="shadow shadow-margin-zero box-negozio-info p-top-30">
                            <div className='col-sm-12'>
                                <p className='color-blue d-flex align-items-center'>
                                  <i className="fas fa-fw fa-list-ol font-20 me-2 p-right-12"></i> 
                                  Liste Marketing
                                </p>
                              </div>
                              <div className='col-sm-12'>
                                  <p className='box-negozio-text'>Ti aiutano ad <strong>aumentare le vendite</strong> grazie a elenchi di potenziali clienti.
                                     Ottimizza il tuo tempo e riduci i costi scaricando le <strong>informazioni in formato Excel</strong> per una gestione più efficiente</p>
                              </div>
                            </Row>
                          </div>
                          <div className="col-sm-6 col-xs-12 p-bottom-50 ">
                          <Row className="shadow shadow-margin-zero box-negozio-info p-top-30">
                            <div className='col-sm-12'>
                                <p className='color-blue d-flex align-items-center'>
                                  <i className="fa fa-circle-info font-20 me-2 p-right-12"></i> 
                                  Dettaglio Impresa
                                </p>
                              </div>
                              <div className='col-sm-12'>
                                  <p className='box-negozio-text'>Consulta <strong>online i dati aziendali</strong>, tra cui anagrafica, contatti, sede, settore, 
                                  <strong>ultimi tre bilanci</strong>, soci ed esponenti, sedi locali, partecipazioni e possibilità di richiedere  <strong>report in formato PDF</strong>
                                  </p>
                              </div>
                            </Row>
                          </div>
                          <div className="col-sm-6 col-xs-12 p-bottom-50">
                          <Row className="shadow shadow-margin-zero box-negozio-info p-top-30">
                            <div className='col-sm-12'>
                                <p className='color-blue d-flex align-items-center'>
                                  <i className="fa-solid fa-building-user font-20 me-2 p-right-12"></i> 
                                  Servizi Infocamere
                                </p>
                              </div>
                              <div className='col-sm-12'>
                                  <p className='box-negozio-text'>MyImprese ti permette di accedere ai <strong>dati ufficiali di InfoCamere</strong>, la società consortile delle Camere di Commercio. Come distributore autorizzato, 
                                    offre documenti ufficiali come la <strong>Visura Camerale e il Bilancio in formato PDF</strong>
                                  </p>
                              </div>
                            </Row>
                          </div>
                        </Row>
                        <h4 className="p-bottom-50">Pacchetti MyImprese pensati per te</h4>
                          <div className='col-sm-12 '>
                            <p className='box-negozio-text shadow shadow-margin-zero box-negozio-info p-5 m-bottom-20'>Scegli il pacchetto più adatto alle tue esigenze. Le nostre soluzioni sono pensate per liberi professionisti, microimprese e aziende più strutturate, garantendo massima <strong>flessibilità e risparmio</strong>. 
                            Nessun obbligo di consumo: tutti i <strong>pacchetti sono senza scadenza</strong>, offrendoti la libertà di utilizzarli quando vuoi<br/>
                            </p>
                            
                        </div>
                        
                        <div className='col-sm-12 col-xs-12'>
  <Row className="shadow shadow-margin-zero p-4 box-negozio-info m-top-50">
    <div className='col-sm-9 d-flex align-items-center text-left'>
      <p className="mb-0">Vuoi maggiori informazioni o un piano personalizzato?</p>
    </div>
    <div className='col-sm-3 d-flex align-items-center justify-content-end'>
      <Button variant="info" className="" 
              disabled={false} 
              onClick={() => handleConatti()}>
        <i className="fa-regular fa-envelope-open"></i> Contattaci Ora
      </Button>
    </div>
  </Row>
</div>

                        
                        
                        
                    
                </div>
            </Row>
            <div className="row justify-content-center">
                <div className="col-xl-9 shadow box-ricerca-elenco row heigth-max margin-row m-top-50 background-blue text-white-public">
                    <div className="col-sm-12 text-center">
                        <h5>Pacchetti Principali <span className="font-0-85 underline-unico">a partire da €49,00 + iva</span></h5>
                    </div>
                </div>
                <div className="col-xl-11 col-lg-12 col-md-12">
                    {/* <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0"> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        {/* <div className=""> */}
                                            
                                            <Row className="row box-piani-dim-boxes control-d">
                                              
                                            {/* <div className="col-sm-12">
                    <div className="row box-piani-dim-boxes control-d"> */}
                    <div className="col-sm-4 box-piani-box">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Premium <br /><span className="box-piani-span">Il più acquistato dai Commercialisti</span></p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">3000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">700</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">2500</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">20</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Gold</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1200</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">400</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">15</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Silver</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">500</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">250</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">250</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </div>
                    </div> */}
                    {/* <PrezziPacchettiResponsive></PrezziPacchettiResponsive> */}
            
                    </div>


                                            </Row>
                                    </div>
                               </div>
                            </div>
                        {/* </div>
                    </div> */}

                </div>
                <PrezziResponsiveNegozioPrincipali></PrezziResponsiveNegozioPrincipali>

                <div className="col-xl-11 col-lg-12 col-md-12">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">

                                            <Row className="row box-piani-dim-boxes control-d">

                    <div className="col-sm-4 box-piani-box p-bottom-30">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Start <br /><span className="box-piani-span">Il più acquistato dalle agenzie</span></p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">200</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div>
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box p-bottom-30">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Basic <br /><span className="box-piani-span">Il più acquistato dalle PMI</span></p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">80</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">20</span>
                                    </div>
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box p-bottom-30">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Essential</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">20</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    {/* <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-12">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-12">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    {/* </div>
                </div> */}
                {/* <PrezziPacchettiResponsive></PrezziPacchettiResponsive> */}
            </div>


                                            </Row>
                                          
                                            
                                        {/* </div> */}
 
                                           
                                        
                                        

                                    </div>
                               </div>
                            </div>
                        {/* </div>
                    </div> */}

                </div>
               

            



            <div className="col-xl-9 shadow box-ricerca-elenco row heigth-max margin-row background-blue text-white-public">
                    <div className="col-sm-12 text-center">
                        <h5>Pacchetti Aggiuntivi <span className="font-0-85 underline-unico">a partire da €29,00 + iva</span></h5>
                    </div>
                </div>
                <div className="col-xl-11 col-lg-12 col-md-12 control-d-2">
                    {/* <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0"> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        {/* <div className=""> */}
                                            
                                            <Row className="row box-piani-dim-boxes-neg control-d">
                                              
                                            {/* <div className="col-sm-12">
                <div className="row box-piani-dim-boxes control-d"> */}
                    <div className="col-sm-4 box-piani-box-small">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Ricerca Imprese</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                  <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">500</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">150</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">150</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">20</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Visure Camerali</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">150</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">20</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Bilanci</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">150</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">20</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    {/* </div>
                </div> */}
                {/* <PrezziPacchettiResponsive></PrezziPacchettiResponsive> */}
            </div>


                                            </Row>
                                    </div>
                               </div>
                            </div>
                        {/* </div>
                    </div> */}

                </div>

                <div className="col-xl-11 col-lg-12 col-md-12">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">

                                            <Row className="row box-piani-dim-boxes-neg control-d">

                    <div className="col-sm-4 box-piani-box-small p-bottom-30">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Report Smart</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report Smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small p-bottom-30">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Report Azienda</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report Azienda</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small p-bottom-30">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Report Azienda Approfondito</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Azienda Approfondito</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                                
                            </div>
                        </div>
                    {/* </div>
                </div> */}
                {/* <PrezziPacchettiResponsive></PrezziPacchettiResponsive> */}
            </div>


                                            </Row>
                                            
                                             <PrezziResponsiveNegozioAggiuntivi></PrezziResponsiveNegozioAggiuntivi>
                                        {/* </div> */}
 
                                           
                                        
                                        

                                    </div>
                               </div>
                            </div>
                        {/* </div>
                    </div> */}

                </div>

                <div className="col-xl-9 shadow box-ricerca-elenco row heigth-max margin-row background-blue text-white-public">
                    <div className="col-sm-12 text-center">
                        <h5>Pacchetti Singoli <span className="font-0-85 underline-unico">a partire da €2,99 + iva</span></h5>
                    </div>
                </div>
                <div className="col-xl-11 col-lg-12 col-md-12 control-d-2">
                    {/* <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0"> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        {/* <div className=""> */}
                                            
                                            <Row className="row box-piani-dim-boxes-neg control-d">
                                              
                                            {/* <div className="col-sm-12">
                <div className="row box-piani-dim-boxes control-d"> */}
                    <div className="col-sm-4 box-piani-box-small-s">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Visura Camerale</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                  <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    {/* <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    {/* <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small-s">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Bilancio</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilancio</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    {/* <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    {/* <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small-s">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Report Smart</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report Smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    {/* <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    {/* <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    
                                </div>
                            </div>
                        </div>
                    {/* </div>
                </div> */}
                {/* <PrezziPacchettiResponsive></PrezziPacchettiResponsive> */}
            </div>


                                            </Row>
                                    </div>
                               </div>
                            </div>
                        {/* </div>
                    </div> */}

                </div>

                <div className="col-xl-11 col-lg-12 col-md-12">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">

                                            <Row className="row box-piani-dim-boxes-neg control-d">

                    <div className="col-sm-4 box-piani-box-small-s ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Report Azienda</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report Azienda</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small-s">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Report Azienda Approfondito</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report Azienda Approfondito</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box-small-s ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">Ricerca Azienda</p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca Azienda</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">30</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">50</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">3</span>
                                    </div> */}
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                    {/* <div className="col-sm-12">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div> */}
                                    {/* <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div> */}
                                </div>
                                
                            </div>
                        </div>
                    {/* </div>
                </div> */}
                {/* <PrezziPacchettiResponsive></PrezziPacchettiResponsive> */}
                
            </div>


                                            </Row>
                                            <PrezziResponsiveNegozioSingoli></PrezziResponsiveNegozioSingoli>
                                            </div>
                                            <Row>
                                              
                                            </Row>
                                            
                                        {/* </div> */}

                                        {/* {jwtToken.data != null &&
                                              <>
                                              
                                              <div className='col-sm-12 col-xs-12'>
                                                  <Row className="shadow shadow-margin-zero p-4 box-negozio-info m-top-50">
                                                    <div className='col-sm-9 d-flex align-items-center text-left'>
                                                      <p className="mb-0">Vuoi provare il servizio? Per te subito 5 Ricerche Gratuite e 2 visualizzazioni dettagliate di imprese</p>
                                                    </div>
                                                    <div className='col-sm-3 d-flex align-items-center justify-content-end'>
                                                      <Button variant="info" className="" 
                                                              disabled={false} 
                                                              onClick={() => handleRegister()}>
                                                        <i className="fa-regular fa-envelope-open"></i> Registrati Ora
                                                      </Button>
                                                    </div>
                                                  </Row>
                                                </div>
                                              </>
                                              

                                              } */}
 
                                           
                                        <div className='col-sm-12 col-xs-12'>
  <Row className="shadow shadow-margin-zero p-4 box-negozio-info m-top-50 m-bottom-30">
  {jwtToken.data == null &&
  <>
   <div className='col-sm-9 d-flex align-items-center text-left p-bottom-30'>
                                                      <p className="mb-0">Vuoi provare il servizio? Per te subito 5 Ricerche Gratuite e 2 visualizzazioni dettagliate di imprese</p>
                                                    </div>
                                                    <div className='col-sm-3 d-flex align-items-center justify-content-end p-bottom-30'>
                                                      <Button variant="info" className="" 
                                                              disabled={false} 
                                                              onClick={() => handleRegister()}>
                                                        <i className="fa-solid fa-user-plus"></i> Registrati Ora
                                                      </Button>
                                                    </div>
  </>
                                                   
}
    <div className='col-sm-9 d-flex align-items-center text-left'>
      <p className="mb-0">Vuoi maggiori informazioni o un piano personalizzato?</p>
    </div>
    <div className='col-sm-3 d-flex align-items-center justify-content-end'>
      <Button variant="info" className="" 
              disabled={false} 
              onClick={() => handleConatti()}>
        <i className="fa-regular fa-envelope-open"></i> Contattaci Ora
      </Button>
    </div>
  </Row>
</div>
                                        

                                    </div>
                               </div>
                            </div>
                        {/* </div>
                    </div> */}

                </div>


                </div>

            

        
        <FooterPage />
    </>
    );
}

