
import { APIService } from "services";

export const parseJwtToken =  (token) => { 
    try {
      // Get Token Header
      const base64HeaderUrl = token.split('.')[0];
      const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
      const headerData = JSON.parse(window.atob(base64Header));
  
      // Get Token payload and date's
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const dataJWT = JSON.parse(window.atob(base64));
      dataJWT.header = headerData;
  
      // TODO: add expiration at check ...
      return dataJWT;
    } catch (err) {
      return false;
    }
}

export const isUserAuthenticate =  () => { 
    const token = localStorage.getItem('token');
    let isUser = true;
    try {
      // Get Token Header
      const base64HeaderUrl = token.split('.')[0];
      const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
      const headerData = JSON.parse(window.atob(base64Header));
  
      // Get Token payload and date's
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const dataJWT = JSON.parse(window.atob(base64));
      dataJWT.header = headerData;
  
      // TODO: add expiration at check ...
      if(dataJWT.data.id === 1) return false;
      return isUser;
    } catch (err) {
      return false;
    }
}