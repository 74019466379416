import React from 'react';
import { Carousel } from 'react-bootstrap';
import "../../styles/homepublic.scss"

const PrezziResponsiveNegozioPrincipali = () => {
  const pricingPlans = [
    {
      name: 'MyImprese Premium',
      subtitle: 'Il più acquistato dai Commercialisti',
      features: [
        { label: 'Ricerca imprese', value: '3000' },
        { label: 'Dettaglio imprese', value: '700' },
        { label: 'Anagrafiche su file', value: '2500' },
        { label: 'Visura Camerale', value: '10' },
        { label: 'Bilanci Impresa', value: '10' },
        { label: 'Report smart', value: '20' },
        { label: 'Report azienda', value: '10' },
        { label: 'Report azienda approfondito', value: '10' }
      ]
    },
    {
      name: 'MyImprese Gold',
      subtitle: '',
      features: [
        { label: 'Ricerca imprese', value: '1200' },
        { label: 'Dettaglio imprese', value: '400' },
        { label: 'Anagrafiche su file', value: '100' },
        { label: 'Visura Camerale', value: '5' },
        { label: 'Bilanci Impresa', value: '5' },
        { label: 'Report smart', value: '15' },
        { label: 'Report azienda', value: '5' },
        { label: 'Report azienda approfondito', value: '5' }
      ]
    },
    {
      name: 'MyImprese Silver',
      subtitle: 'Il più acquistato dalle PMI',
      features: [
        { label: 'Ricerca imprese', value: '500' },
        { label: 'Dettaglio imprese', value: '250' },
        { label: 'Anagrafiche su file', value: '250' },
        { label: 'Visura Camerale', value: '5' },
        { label: 'Bilanci Impresa', value: '5' },
        { label: 'Report smart', value: '15' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'MyImprese Start',
      subtitle: 'Il più acquistato dalle Agenzie',
      features: [
        { label: 'Ricerca imprese', value: '200' },
        { label: 'Dettaglio imprese', value: '50' },
        { label: '', value: '' },
        { label: 'Visura Camerale', value: '3' },
        { label: 'Bilanci Impresa', value: '3' },
        { label: 'Report smart', value: '10' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'MyImprese Basic',
      subtitle: 'Il più acquistato dalle Agenzie',
      features: [
        { label: 'Ricerca imprese', value: '80' },
        { label: 'Dettaglio imprese', value: '20' },
        { label: '', value: '' },
        { label: 'Visura Camerale', value: '1' },
        { label: 'Bilanci Impresa', value: '1' },
        { label: 'Report smart', value: '5' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    },
    {
      name: 'MyImprese Essential',
      subtitle: 'Il più acquistato dalle Agenzie',
      features: [
        { label: 'Ricerca imprese', value: '20' },
        { label: 'Dettaglio imprese', value: '5' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
        { label: 'Report smart', value: '5' },
        { label: '', value: '' },
        { label: '', value: '' }
      ]
    }
  ];

  return (
    <div className="col-sm-12 control-r">
      <Carousel>
        {pricingPlans.map((plan, index) => (
          <Carousel.Item key={index}>
            <div className="col-sm-12 box-piani-box">
              <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                <div className="col-sm-12 box-piani-boxes-header">
                  <p className="box-testimonial-title p-lr-30">
                    {plan.name} <br />
                    <span className="box-piani-span">{plan.subtitle}</span>
                  </p>
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    {plan.features.map((feature, featureIndex) => (
                      <React.Fragment key={featureIndex}>
                        <div className="col-sm-9">
                          <span className="box-piani-row-text">{feature.label}</span>
                        </div>
                        <div className="col-sm-3">
                          <span className="box-piani-num">{feature.value}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default PrezziResponsiveNegozioPrincipali;